<template>
     <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="d-flex flex-wrap flex-wrap align-items-center justify-content-between mb-4">
                    <div>
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br>{{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<u v-b-modal.modal-3 variant="link" style="color:blue;">{{desc3}}</u></p>
                        <b-modal id="modal-3" size="xl" title="1688 주문서 업로드 방법" ok-title="Save Changes" cancel-title="Close">
                            <p>생성한 주문서를 <a href="https://www.1688.com" target="_blank"><u>1688</u></a>에 접속해서 아래의 순서로 업로드해주세요</p>
                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/1688주문서업로드.png')">
                        </b-modal>
                    </div>
                </div>
                <card>
                     <div class="p-3">
                        <form  @submit.prevent="submit" >
                            <div class="row">
                                <!-- <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="custom-control custom-switch custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" id="customSwitch1" checked="" v-model="CreatePo_주문번호">
                                            <label class="custom-control-label" for="customSwitch1" v-b-tooltip.bottom="' 1688 주문 시 [주문번호]당 배송비가 별도로 발생합니다. 주문번호를 발주서번호로 생성할 경우 자동으로 발주서번호가 주문번호로 기입되지만, 같은 제품이 서로 다른 발주서에 있을 경우에도 배송비가 별도로 발생할 수 있는 점 유의해 주세요.'">&nbsp;발주번호를 주문번호로 생성</label>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="ml-1">수취인명</label>
                                        <input type="text" class="form-control" placeholder="Company ID" v-model="CreatePo_수취인">
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label class="ml-1">연락처</label>
                                        <input type="text" class="form-control" placeholder="phone number" v-model="CreatePo_연락처">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="ml-1">Province (성)</label>
                                        <input type="text" class="form-control" placeholder="Province" v-model="CreatePo_주소_성">
                                    </div>
                                </div>  
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="ml-1">City (시)</label>
                                        <input type="text" class="form-control" placeholder="City" v-model="CreatePo_주소_시">
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label class="ml-1">District (구)</label>
                                        <input type="text" class="form-control" placeholder="District" v-model="CreatePo_주소_구">
                                    </div>
                                </div>   
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="ml-1">Detail Address (상세주소)</label>
                                        <input type="text" class="form-control" placeholder="Detail Address" v-model="CreatePo_상세주소">
                                    </div>
                                </div>

<!--                                 
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="ml-1">발주일(시작일)</label>
                                        <b-form-datepicker id="example-datepicker1" v-model="startdate" class="mb-2"></b-form-datepicker>
                                    </div>
                                </div>              
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="ml-1">발주일(종료일)</label>
                                        <b-form-datepicker id="example-datepicker2" v-model="enddate" class="mb-2"></b-form-datepicker>
                                    </div>
                                </div>              
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label class="ml-1" v-b-tooltip.right="'입고예정일을 맞추기 어려운 발주서는 생략하고, 특정 기간(최대발주기간) 이후의 발주서에 대해서만 주문서를 생성합니다. 필요없을 경우 0으로 기입해주세요'">최대발주기간</label>
                                        <input type="text" class="form-control" style="height:60px" placeholder="ex) 14" v-model="CreatePo_발주기준일">
                                    </div>
                                </div> -->
                                <div class="col-md-12">
                                    <p class="mb-0" @click="excelDownFunc"><u variant="link" style="color:blue;">업로드 양식 다운로드</u></p>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <div class="custom-file">
                                        <input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
                                        <label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
                                        </div>
                                    </div>
                                </div>  

                            </div>                            
                            <!-- <button type="submit" class="btn btn-primary mr-2">List 생성</button> -->
                            <button type="button" class="btn btn-primary mr-2" @click="downloadpo">1688 주문서 생성</button>
                            <button type="button" class="btn btn-secondary mr-2" @click="savesetting">기본 SETTING으로 저장</button>
                            <button type="reset" class="btn btn-danger">Reset</button>
                        </form>
                    </div>
                </card>
                <!-- <div class="col-lg-12" v-if="showlist">
                    <div class="table-responsive rounded mb-3">
                        <tableList>
                            <template v-slot:header>
                                <tr class="ligth ligth-data">
                                    <th>
                                        <div class="checkbox d-inline-block">
                                            <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allchecked" @change="allchecklist(allchecked)">
                                            <label for="checkbox1" class="mb-0"></label>
                                        </div>
                                    </th>
                                    <th>발주번호</th>
                                    <th>발주일시</th>
                                    <th>입고예정일</th>
                                    <th>발주수량</th>
                                    <th>구매필요수량</th>
                                    <th>재고수량</th>
                                    <th>사입재고수량</th>
                                    <th>회송재고수량</th>
                                    <th>Actions</th>
                                </tr>
                            </template>
                            <template v-slot:body>
                                <tr v-for="(list,index) in lists" :key="index">
                                    <td>
                                        <div class="checkbox d-inline-block">
                                            <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                            <label for="checkbox2" class="mb-0"></label>
                                        </div>
                                    </td>
                                    <td>{{list.발주번호}}</td>
                                    <td>{{list.발주일시}}</td>
                                    <td>{{list.입고예정일}}</td>
                                    <td>{{list.전체수량}}</td>
                                    <td style="color:red;font-weight:bold">{{list.구매필요수량}}</td>
                                    <td>{{list.재고수량}}</td>
                                    <td>{{list.사입재고수량}}</td>
                                    <td>{{list.회송재고수량}}</td>
                                    <td>
                                        <div class="d-flex align-items-center list-action">
                                            <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View" href="javascript:void(0)" v-b-modal="modalID(index)">
                                                <i class="ri-eye-line mr-0"></i>
                                            </a> 

                                                                <b-modal :id="'modal'+index" scrollable title="발주세부내역" ok-title="OK" cancel-title="Close" class="detail-modal">                                                                    
                                                                    <div class="table-responsive">
                                                                        <table id="datatable" class="table data-table table-striped dataTable" >
                                                                        <thead>
                                                                            <tr class="ligth">
                                                                                <th style="min-width:100px;">SKU ID</th>
                                                                                <th style="min-width:200px;">상품명</th>
                                                                                <th v-for="m,i1 in menupodetail" :key="i1">{{m}}</th>
                                                                                <th>Actions</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-for="a,i2 in list.발주세부내역" :key="i2" role="row">
                                                                                <td>
                                                                                    <div class="d-flex align-items-center">
                                                                                        <img :src="a.IMGURL" class="img-fluid rounded avatar-80 mr-3" alt="image">
                                                                                        <div>{{a.SKUID}}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{a["상품명"]}}</td>
                                                                                <td v-for="m,i3 in menupodetail" :key="i3">{{a[m]}}</td>
                                                                                <td>
                                                                                    <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletedetail(list.발주번호,a.SKUID)">
                                                                                        <i class="ri-delete-bin-line mr-0"></i>
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        </table>
                                                                    </div>        
                                                                </b-modal>


                                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletelist(list.발주번호)">
                                                <i class="ri-delete-bin-line mr-0"></i>
                                            </a> 
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tableList>
                    </div>
                </div>
                <div class="col-lg-12 mb-4" v-if="showlist">
                    <button type="button" class="btn btn-primary mr-2" @click="downloadtotal">전체 주문서 다운로드</button>
                    <button type="button" class="btn btn-secondary mr-2" @click="downloadselect">선택 주문서 다운로드</button>                
                </div>

                                   


                <div class="col-lg-12" v-if="showdetaillist">
                    <div class="table-responsive rounded mb-3">
                        <tableList>
                            <template v-slot:header>
                                <tr class="ligth ligth-data">
                                    <th>
                                        <div class="checkbox d-inline-block">
                                            <input type="checkbox" class="checkbox-input" id="checkbox1" v-model="allcheckdetail" @change="allcheckdetaillist(allcheckdetail)">
                                            <label for="checkbox1" class="mb-0"></label>
                                        </div>
                                    </th>
                                    <th>check</th>
                                    <th>Email</th>
                                    <th>Company</th>
                                    <th>Awors Points</th>
                                    <th>Group</th>
                                    <th>Status</th>
                                    <th>Store</th>
                                    <th>Action</th>
                                </tr>
                            </template>
                            <template v-slot:body>
                                <tr v-for="(list,idx) in detaillists" :key="idx">
                                    <td>
                                        <div class="checkbox d-inline-block">
                                            <input type="checkbox" class="checkbox-input" id="checkbox2" v-model="list.checked">
                                            <label for="checkbox2" class="mb-0"></label>
                                        </div>
                                    </td>
                                    <td>{{list.name}}</td>
                                    <td>{{list.email}}</td>
                                    <td>{{list.company}}</td>
                                    <td>0</td>
                                    <td>{{list.group}}</td>
                                    <td>{{list.status}}</td>
                                    <td>{{list.store}}</td>
                                    <td>
                                        <div class="d-flex align-items-center list-action">
                                            <a class="badge badge-info mr-2" data-toggle="tooltip" v-b-tooltip.top title="View" href="javascript:void(0)">
                                                <i class="ri-eye-line mr-0"></i>
                                            </a> 
                                            <a class="badge bg-success mr-2" data-toggle="tooltip" v-b-tooltip.top title="Edit" href="javascript:void(0)">
                                                <i class="ri-pencil-line mr-0"></i>
                                            </a> 
                                            <a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)">
                                                <i class="ri-delete-bin-line mr-0"></i>
                                            </a> 
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </tableList>
                    </div>
                </div> -->

            </div>
        </div>
        <!-- Page end  -->
    </div>
</template>
<script>
import * as XLSX from 'xlsx';
import axios from 'axios';
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'   
// import { VueEditor } from 'vue2-editor'
export default {
    name:'Addpurchase',
     mixins: [validationMixin],
    validations: {
      purchaseno: { required },
      payment: { required ,integer},
      },
    components:{
    //    VueEditor,
    },
    data(){
        return{
            title:"1688 주문서 생성",
            desc1:"1688 주문서가 100줄이 넘어갈 경우 여러개의 파일로 나누어 다운로드 됩니다.",
            desc2:"다운로드된 주문서를 수정하지 말고 업로드해 주세요.",
            desc3:"1688 주문서 업로드 방법",

            customToolbar: [["bold",  "underline","italic","image","code"]],
            purchaseno:'',
            payment:'',

            allchecked: false,

            CreatePo_수취인 : this.$store.state.settings[0].CreatePo_수취인,
            CreatePo_연락처 : this.$store.state.settings[0].CreatePo_연락처,
            CreatePo_주소_성 : this.$store.state.settings[0].CreatePo_주소_성,
            CreatePo_주소_시 : this.$store.state.settings[0].CreatePo_주소_시,
            CreatePo_주소_구 : this.$store.state.settings[0].CreatePo_주소_구,
            CreatePo_상세주소 : this.$store.state.settings[0].CreatePo_상세주소,
            CreatePo_주문번호 : this.$store.state.settings[0].CreatePo_주문번호,
            CreatePo_발주기준일 : this.$store.state.settings[0].CreatePo_발주기준일,

            startdate: this.getTodaywith(),
            enddate: this.getTodaywith(),

            showlist: false,
            lists: [],

            poinfocnt : 0,

            allcheckdetail : false,
            detaillists: [],
            totallists: [],
            addlists: [],
            stocklists: [],
            sstocklists: [],
            rstocklists: [],
            showdetaillist:false,
            
            downloadlists: [],

            uploadstatus:'excel file upload',

            // totalcount: this.gettotalcnt(),
            // addcount: this.getaddcnt(),

            menupodetail: ["바코드","발주수량","확정수량","구매필요수량","미배치재고수량","회송예정수량","배치수량","사입재고수량"],
            
        }
    },
     methods: {
        excelDownFunc(){
            var arr = [];
            const myHeader = ["SKUID","구매필요수량"];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, '1688구매업로드양식');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '1688구매업로드양식.xlsx');
        },
         uploadfile(event) {  
            this.uploadstatus ='excel file upload';
            // var arr = [];
            var obj = {};
            var ob = {};
            // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            const file = event.target.files[0];
            let reader = new FileReader();
            reader.onload = (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    roa.forEach(e => {
                        if(!this.$store.state.productdb.find(ele => ele['SKU ID'] == e.SKUID)){
                            alert(e.SKUID + '는 상품DB에 존재하지 않는 제품입니다.');
                            this.downloadlists = [];
                            return
                        }
                        obj = this.deepClone(this.$store.state.productdb.find(ele => ele['SKU ID'] == e.SKUID))
                        if(obj.판매처){
                            var 판매처 = obj.판매처
                        } else {
                            판매처 = '';
                        }
                        if(obj.옵션2_중국어){
                            var option2 = obj.옵션2_중국어
                        } else {
                            option2 = '';
                        }
                        ob = {
                            '订单号':this.$store.state.user.companyid + "-" + this.getToday(),
                            '商品名称':obj.상품명,
                            '商品数量(件)':e.구매필요수량,
                            '商品规格1（如：颜色）': obj.옵션1_중국어,
                            '商品规格2（如：尺码）': option2,
                            '收件人-姓名': this.CreatePo_수취인,
                            '收件人-手机': this.CreatePo_연락처,
                            '收件人-省':this.CreatePo_주소_성,
                            '收件人-市':this.CreatePo_주소_시,
                            '收件人-区':this.CreatePo_주소_구,
                            '收件人-详细地址':this.CreatePo_상세주소,
                            '收货地址（省/市/区/详细地址合并的收货地址）':'',
                            '买家留言':e.SKUID,
                            '1688商品链接/1688商品id':obj.구매링크.slice(30,obj.구매링크.search('.html')),
                            '分销or现货（默认或者填1走分销，0走现货）':0,
                            판매처:판매처
                        }
                        this.downloadlists.push(ob);
                    })
                    this.uploadstatus ='Upload 완료';
                    console.log(this.downloadlists)
                    
                });
            };
            reader.readAsBinaryString(file);
        },
        modalID(index) {
            // console.log(index);
            return 'modal' + index
        },
        downloadxlsx(lists){
            if(lists.length > 0){
                var createpo = [];
                var totalpo = [];
                //this.lists, setting값에 따라 createpo 업데이트
                var obj = {};
                var link
                var option1
                var option2
                lists.forEach(e => {
                    e.발주세부내역.forEach(ele => {
                        link = '';
                        option1 = '';
                        option2 = '';
                        this.$store.state.productdb.some(element => {
                            if(element.바코드 == ele.바코드){
                                link = element.구매링크;
                                option1 = element.옵션1_중국어;
                                option2 = element.옵션2_중국어;
                                return (element.바코드 == ele.바코드)
                            }
                        })
                        if(ele.구매필요수량>0){
                            if(link == ''){
                                alert('"' + ele.바코드 + '" 제품의 구매링크 정보가 없습니다.')
                            } else {
                                obj = {
                                    '订单号':e.발주번호,
                                    '商品名称':ele.상품명,
                                    '商品数量(件)':ele.구매필요수량,
                                    '商品规格1（如：颜色）': option1,
                                    '商品规格2（如：尺码）': option2,
                                    '收件人-姓名': this.CreatePo_수취인,
                                    '收件人-手机': this.CreatePo_연락처,
                                    '收件人-省':this.CreatePo_주소_성,
                                    '收件人-市':this.CreatePo_주소_시,
                                    '收件人-区':this.CreatePo_주소_구,
                                    '收件人-详细地址':this.CreatePo_상세주소,
                                    '收货地址（省/市/区/详细地址合并的收货地址）':'',
                                    '买家留言':ele.SKUID,
                                    '1688商品链接/1688商品id':link.slice(30,link.search('.html')),
                                    '分销or现货（默认或者填1走分销，0走现货）':0,
                                }
                                totalpo.push(obj);
                            }
                        }
                    })
                })

                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })

                //주문번호 변경 (발주번호 --> timestamp0001)
                var matchpo = [];
                var matchobj = {};
                var ponum = '';

                var now = Date.now()
                var n = 0;
                totalpo.forEach((e,i) => {
                    matchobj = {};
                    if(i > 0){
                        if(totalpo[i-1]['1688商品链接/1688商品id'] != totalpo[i]['1688商品链接/1688商品id']){
                            n++
                        }
                    }
                    ponum = now.toString() + (n+1).toString().padStart(4,'0')
                    matchobj = {
                        쿠플러스주문번호: ponum,
                        발주번호: e['订单号'],
                        SKUID: e['买家留言'],
                        상품명: e['商品名称'],
                        구매수량: e['商品数量(件)'],
                        상품번호: e['1688商品链接/1688商品id'],
                        option1: e['商品规格1（如：颜色）'],
                        option2: e['商品规格2（如：尺码）']
                    };
                    matchpo.push(matchobj);
                    e['订单号'] = ponum;
                })
                //axios matchpo
                
                axios.post('/api/addpurchase/addmatchpo',matchpo)
                .then((res) => {
                    this.$store.commit('matchpoupdate',res.data.matchpo);
                    alert(res.data.요청결과)
                })
                .catch(console.log)


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;
                
                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }    
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')
                

                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        downloadtotal(){
            //this.lists를 1688주문서로 변환해서 다운로드
            this.downloadxlsx(this.lists);
        },
        downloadselect(){
            var selectlists = [];
            this.lists.forEach(e => {
                if(e.checked == true){
                    selectlists.push(e)
                }
            })
            this.downloadxlsx(selectlists);
        },
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		},        
        deletelist(발주번호){
            var newarr = [];
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } 
            })
            this.lists = newarr;
        },
        deletedetail(발주번호,SKUID){
            var newarr = [];
            var newobj = {};
            this.lists.forEach(e => {
                if(e.발주번호 != 발주번호){
                    newarr.push(e);
                } else {
                    newobj = this.deepClone(e);
                    newobj.발주세부내역 = [];
                    e.발주세부내역.forEach(ele => {
                        if(ele.SKUID != SKUID){
                            newobj.발주세부내역.push(ele);
                        } 
                    });
                    newarr.push(newobj);
                }
            })
            this.lists = newarr;
        },        
        allchecklist(allchecked){
            if(allchecked == true){
                this.lists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.lists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        allcheckdetaillist(allcheckdetail){
            if(allcheckdetail == true){
                this.detaillists.forEach(e => {
                    e.checked = true;
                })
            } else {
                this.detaillists.forEach(e => {
                    e.checked = false;
                })
            }
        },
        getTodaywith(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + "-" + month + "-" + day;
        },       
        getToday(){
            var date = new Date();
            var year = date.getFullYear();
            var month = ("0" + (1 + date.getMonth())).slice(-2);
            var day = ("0" + date.getDate()).slice(-2);

            return year + month + day;
        },
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime() / 1000
        },
        downloadpo(){
            if(this.downloadlists.length > 0){
                var createpo = [];
                var totalpo = this.downloadlists;
                

                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a['1688商品链接/1688商品id'] < b['1688商品链接/1688商品id']){
                        return -1;
                    }
                    if (a['1688商品链接/1688商品id'] > b['1688商品链接/1688商品id']){
                        return 1;
                    }
                    return 0;
                })
                //1688id순으로 정렬 (중국내배송비최소화)
                totalpo.sort(function(a,b){
                    if (a.판매처 < b.판매처){
                        return -1;
                    }
                    if (a.판매처 > b.판매처){
                        return 1;
                    }
                    return 0;
                })                


                //1688po를 100개씩 잘라서 createpo array에 저장
                console.log(totalpo)
                var i
                var j
                var cnt = totalpo.length;
                
                for(j=0;j<cnt;j++){
                    if(totalpo.length < 100){
                        createpo.push(totalpo)
                        break
                    }    
                    for(i=100;i>0;i--){
                        if(totalpo[i][['1688商品链接/1688商品id']] != totalpo[i-1][['1688商品链接/1688商品id']]){
                            console.log(totalpo.slice(0,i))
                            createpo.push(totalpo.slice(0,i))
                            totalpo = totalpo.slice(i,totalpo.length)
                            console.log(totalpo)
                            break
                        } else {
                            if(i==1){
                                createpo.push(totalpo.slice(0,100))
                                totalpo = totalpo.slice(100,totalpo.length)
                            }
                        }
                    }
                }

                //test
                // const workBook = XLSX.utils.book_new()
                // const workSheet1 = XLSX.utils.json_to_sheet(totalpo)
                // const workSheet2 = XLSX.utils.json_to_sheet(matchpo)
                // XLSX.utils.book_append_sheet(workBook, workSheet1, '1688주문서')
                // XLSX.utils.book_append_sheet(workBook, workSheet2, 'matching')
                // XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '.xlsx')
                

                //다운로드
                for(i=0;i<createpo.length;i++){
                    const workBook = XLSX.utils.book_new()
                    const workSheet = XLSX.utils.json_to_sheet(createpo[i])
                    XLSX.utils.book_append_sheet(workBook, workSheet, '1688주문서')
                    XLSX.writeFile(workBook, '1688주문서' + '_' + this.getToday() + '_' + i.toString() + '.xlsx')
                }
            } else {
                alert('생성할 주문서가 없습니다.')
            }
        },
        submit() {
            if(this.poinfocnt == 0){
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.lists = [];
                    this.submitStatus = true
                    var obj = {};
                    var sobj = {};
                    var ssobj = {};
                    var robj = {};
                    var addobj = {};
                    var addcnt = 0;
                    var rstockcnt = 0;
                    var stockcnt = 0;
                    var sstockcnt = 0;
                    var elcnt = 0;
                    var existaddcnt = 0;
                    var arrangecnt = 0;
                    var notarrangecnt = 0;
                    var pstockcnt = 0;
                    //setting값에 따라 lists 업데이트
                    
                    //날짜로 this.totallists를 추려서 저장
                    this.totallists = this.$store.state.polist.filter(e => this.timestamp(this.startdate) <= this.timestamp(e.발주일시.slice(0,10)) && this.timestamp(this.enddate) >= this.timestamp(e.발주일시.slice(0,10)));
                    //this.totallists에서 lists 추출
                    this.totallists.forEach(e => {
                        e.발주세부내역.forEach(ele => {
                            arrangecnt = 0;
                            notarrangecnt = 0;
                            pstockcnt = 0;
                            rstockcnt = 0;
                            if (this.$store.state.stock.length != 0){
                                this.$store.state.stock.forEach(element => {
                                    if(element.바코드 == ele.바코드){
                                        if(element.배치발주번호 == e.발주번호){
                                            arrangecnt++
                                        } else {
                                            if(element.배치발주번호 == ''){
                                                if(element.Stockinfo == 'stock') {
                                                    if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                        notarrangecnt++
                                                    }
                                                } else {
                                                    if(element.Stockinfo == 'Pstock') {
                                                        pstockcnt++
                                                    } else {
                                                        if(element.Stockinfo == 'Rstock') {
                                                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                                                rstockcnt++
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                })
                            }
                            ele.발주수량 = parseInt(ele.발주수량);
                            ele.입고수량 = parseInt(ele.입고수량);
                            if(isNaN(ele.입고수량)){
                                ele.입고수량 = 0;
                            }
                            ele.확정수량 = parseInt(ele.확정수량);
                            ele.배치수량 = arrangecnt;
                            ele.미배치재고수량 = notarrangecnt;
                            ele.구매완료수량 = pstockcnt;
                            ele.회송예정수량 = rstockcnt;
                            ele.사입재고수량 = 0;
                            if(parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt > 0){
                                this.$store.state.productdb.some(element => {
                                    if(ele.바코드 == element.바코드 && element.구매링크 == '사입'){
                                        ele.사입재고수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt;
                                        return (ele.바코드 == element.바코드 && element.구매링크 == '사입')
                                    }
                                })
                            }
                            ele.구매필요수량 = parseInt(ele.확정수량) - arrangecnt - notarrangecnt - pstockcnt - rstockcnt - ele.사입재고수량;
                        })
                    })



                    if(this.totallists.length == 0){
                        this.showlist = false;
                        alert('생성할 리스트가 없습니다.')
                    } else {

                        this.totallists.forEach(e => {
                            addcnt = 0;
                            rstockcnt = 0;
                            stockcnt = 0;
                            sstockcnt = 0;  

                            e.발주세부내역.forEach(element => {
                                existaddcnt = addcnt;
                                addcnt += parseInt(element.발주수량);
                                this.$store.state.productdb.some(el => {
                                    if(el.바코드 == element.바코드){
                                        if(el.구매링크 == '사입'){
                                            sstockcnt += element.발주수량;
                                            ssobj = {
                                                checked : false,
                                                발주번호 : e.발주번호,
                                                발주센터 : e.발주센터,
                                                입고예정일 : e.입고예정일,
                                                발주운송 : e.발주운송,
                                                SKUID : element.SKUID,
                                                바코드 : element.바코드,
                                                상품명 : element.상품명,
                                                발주수량 : element.발주수량,
                                                매입가 : element.매입가,
                                                공급가액 : element.공급가액,
                                                부가세액 : element.부가세액,
                                            };
                                            this.sstocklists.push(ssobj);
                                            addcnt -= parseInt(element.발주수량);
                                        }
                                        return (el.바코드 == element.바코드)
                                    }
                                })
                                if(existaddcnt < addcnt){
                                    this.$store.state.stock.some(ele => {
                                        if(ele.바코드 == element.바코드){
                                            if(ele.stockinfo == 'stock'){
                                                stockcnt++
                                                if(this.stocklists.length > 0){
                                                    elcnt = 0;
                                                    this.stocklists.forEach(el => {
                                                        if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                            el.수량++
                                                            elcnt++
                                                        }
                                                    })
                                                    if(elcnt == 0){
                                                        sobj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            재고박스번호 : ele.재고박스번호, //표에서 show
                                                            재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                            수량 : 1, //표에서 show
                                                        };
                                                        this.stocklists.push(sobj);  
                                                    }
                                                } else {
                                                    sobj = {
                                                        checked : false,
                                                        발주번호 : e.발주번호,
                                                        발주센터 : e.발주센터,
                                                        입고예정일 : e.입고예정일,
                                                        발주운송 : e.발주운송,
                                                        SKUID : element.SKUID,
                                                        바코드 : element.바코드,
                                                        상품명 : element.상품명,
                                                        발주수량 : element.발주수량, //표에서 hide
                                                        매입가 : element.매입가,
                                                        공급가액 : element.공급가액,
                                                        부가세액 : element.부가세액,

                                                        재고박스번호 : ele.재고박스번호, //표에서 show
                                                        재고파렛트번호 : ele.재고파렛트번호, //표에서 show
                                                        수량 : 1, //표에서 show
                                                    };
                                                    this.stocklists.push(sobj);  
                                                }
                                                
                                                addcnt--
                                                return (addcnt == existaddcnt)
                                            } else {
                                                if(ele.stockinfo == 'Rstock'){
                                                    rstockcnt++
                                                    if(this.rstocklists.length > 0){
                                                        elcnt = 0;
                                                        this.rstocklists.forEach(el => {
                                                            if(el.발주번호 == e.발주번호 && el.바코드 == element.바코드){
                                                                el.수량++
                                                                elcnt++
                                                            }
                                                        })
                                                        if(elcnt == 0){
                                                            robj = {
                                                                checked : false,
                                                                발주번호 : e.발주번호,
                                                                발주센터 : e.발주센터,
                                                                입고예정일 : e.입고예정일,
                                                                발주운송 : e.발주운송,
                                                                SKUID : element.SKUID,
                                                                바코드 : element.바코드,
                                                                상품명 : element.상품명,
                                                                발주수량 : element.발주수량, //표에서 hide
                                                                매입가 : element.매입가,
                                                                공급가액 : element.공급가액,
                                                                부가세액 : element.부가세액,

                                                                수량 : 1 //표에서 show
                                                            };
                                                            this.rstocklists.push(robj);                                                         
                                                        }
                                                    } else {
                                                        robj = {
                                                            checked : false,
                                                            발주번호 : e.발주번호,
                                                            발주센터 : e.발주센터,
                                                            입고예정일 : e.입고예정일,
                                                            발주운송 : e.발주운송,
                                                            SKUID : element.SKUID,
                                                            바코드 : element.바코드,
                                                            상품명 : element.상품명,
                                                            발주수량 : element.발주수량, //표에서 hide
                                                            매입가 : element.매입가,
                                                            공급가액 : element.공급가액,
                                                            부가세액 : element.부가세액,

                                                            수량 : 1 //표에서 show
                                                        };
                                                        this.rstocklists.push(robj); 
                                                    }

                                                    addcnt--
                                                    return (addcnt == existaddcnt)
                                                }
                                            }
                                        } 
                                    });
                                }
                                if(addcnt > 0){
                                    addobj = {
                                        checked : false,
                                        발주번호 : e.발주번호,
                                        발주센터 : e.발주센터,
                                        입고예정일 : e.입고예정일,
                                        발주운송 : e.발주운송,
                                        SKUID : element.SKUID,
                                        바코드 : element.바코드,
                                        상품명 : element.상품명,
                                        발주수량 : element.발주수량,
                                        매입가 : element.매입가,
                                        공급가액 : element.공급가액,
                                        부가세액 : element.부가세액,

                                        수량 : addcnt,
                                    }
                                    this.addlists.push(addobj);
                                }
                            })
                            
                            obj = {
                                checked : false,
                                발주번호 : e.발주번호,
                                발주일시 : e.발주일시,
                                입고예정일 : e.입고예정일,
                                전체수량 : e.발주수량,
                                구매필요수량 : addcnt,
                                재고수량 : stockcnt,
                                사입재고수량 : sstockcnt,
                                회송재고수량 : rstockcnt,
                                발주세부내역 : e.발주세부내역
                            }
                            this.lists.push(obj);
                            this.showlist = true;
                        })
                        
                        //리스트
                        //발주번호 - 전체수량 x개, 필요수량 x개, 회송재고 x개, 재고 x개, 사입재고 x개, 구매링크 필요수 x개 (상품DB 업데이트 필요))
                    }
                } else {
                    this.submitStatus = false;
                    alert('주문서 생성 실패')
                }
            } else {
                alert('1688주문서를 생성하려면 Products > 상품DB등록 메뉴에서 ' + this.poinfocnt + '개의 상품DB에 대한 구매정보(구매링크,중국어옵션명)를 업데이트 해주세요')
            }
        },
        savesetting() {
            var payload = {
                companyid : this.$store.state.user.companyid,
                CreatePo_수취인 : this.CreatePo_수취인,
                CreatePo_연락처 : this.CreatePo_연락처,
                CreatePo_주소_성 : this.CreatePo_주소_성,
                CreatePo_주소_시 : this.CreatePo_주소_시,
                CreatePo_주소_구 : this.CreatePo_주소_구,
                CreatePo_상세주소 : this.CreatePo_상세주소,
                CreatePo_주문번호 : this.CreatePo_주문번호,
                CreatePo_발주기준일 : this.CreatePo_발주기준일
            };

            this.$store.commit('settingsupdate', payload)
            console.log(this.$store.state.settings)
            axios.post('/api/addpurchase/addsettings',payload)
            .then((res) => {alert(res.data.요청결과)})
        }
    },
    mounted() {
        this.$nextTick(function () {
            //상품dB에 구매정보 업데이트가 필요한 상품수
            this.$store.state.productdb.forEach(e => {
                if(e.구매링크 && e.구매링크.length < 1){
                    this.poinfocnt++    
                }
            })
        })
    }
}
</script>
<style scoped>
#quill-container{
    height: 50px;
}
#quill-container .ql-editor{
    min-height: unset;
}

.modal-dialog {
    max-width: 90%;
    position: absolute; 
    
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}



</style>